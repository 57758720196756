import { Feature } from '@arcadehq/shared/types'

import { AccountMixin } from '../AccountCore'
import { getSubfield, setOrDeleteSubfield } from '../helpers'

export interface Privacy {
  preventIndexing: boolean
  disableIpTracking: boolean
  disableEventTracking: boolean
  disableInternalTracking: boolean

  setPreventIndexing: (preventIndexing: boolean) => Promise<boolean>
  setDisableIpTracking: (disableIpTracking: boolean) => Promise<boolean>
  setDisableEventTracking: (disableEventTracking: boolean) => Promise<boolean>
}

export const privacy: AccountMixin<Privacy> = (
  core,
  { team, userProfile }
) => ({
  get preventIndexing() {
    return !!getSubfield(
      team ?? userProfile,
      'features',
      Feature.PreventIndexing
    )
  },

  get disableIpTracking() {
    return !!getSubfield(
      team ?? userProfile,
      'features',
      Feature.DisableIPTracking
    )
  },

  get disableEventTracking() {
    return !!getSubfield(team ?? userProfile, 'features', Feature.DoNotTrack)
  },

  get disableInternalTracking() {
    return !!getSubfield(
      team ?? userProfile,
      'features',
      Feature.DisableInternal3rdPartyTrackers
    )
  },

  setPreventIndexing(preventIndexing: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'features',
        Feature.PreventIndexing,
        preventIndexing,
        core.userId
      )
    } else {
      return setOrDeleteSubfield(
        userProfile,
        'features',
        Feature.PreventIndexing,
        preventIndexing,
        core.userId
      )
    }
  },

  setDisableIpTracking(disableIpTracking: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'features',
        Feature.DisableIPTracking,
        disableIpTracking,
        core.userId
      )
    } else {
      return setOrDeleteSubfield(
        userProfile,
        'features',
        Feature.DisableIPTracking,
        disableIpTracking,
        core.userId
      )
    }
  },

  setDisableEventTracking(disableEventTracking: boolean) {
    if (team) {
      return setOrDeleteSubfield(
        team,
        'features',
        Feature.DoNotTrack,
        disableEventTracking,
        core.userId
      )
    } else {
      return setOrDeleteSubfield(
        userProfile,
        'features',
        Feature.DoNotTrack,
        disableEventTracking,
        core.userId
      )
    }
  },
})
